import axios from 'axios'
import router from '@/router'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import { getToken, setToken } from '@/utils/auth'
import { useUserStore } from '@/store/user'
import { initializeApp } from 'firebase/app'
import { getDatabase, ref as sRef, set } from 'firebase/database'
const firebaseConfig = {
  apiKey: 'AIzaSyD0toyKYhbSk50Bio5O6XsBaxhk1g7KpIs',
  authDomain: 'crypto-symbol-345815.firebaseapp.com',
  databaseURL: 'https://crypto-symbol-345815-default-rtdb.firebaseio.com',
  projectId: 'crypto-symbol-345815',
  storageBucket: 'crypto-symbol-345815.appspot.com',
  messagingSenderId: '296522975823',
  appId: '1:296522975823:web:76262500af21278836783a',
  measurementId: 'G-6S82HHMZPH'
}

// Initialize <Firebase></Firebase>
const app = initializeApp(firebaseConfig)

let reqConfig
let loadingE

const service = axios.create()
// 請求拦截
service.interceptors.request.use(
  (request) => {
    const config = request

    // 調試信息
    // console.log('Response Config:', config)
    // console.log('isAlertErrorMsg:', config.isAlertErrorMsg)

    // token setting
    request.headers['AUTHORIZE_TOKEN'] = getToken()
    request.headers['Authorization'] = getToken()
    /* download file*/
    if (request.isDownLoadFile) {
      request.responseType = 'blob'
    }
    /* upload file*/
    if (request.isUploadFile) {
      request.headers['Content-Type'] = 'multipart/form-data'
    }
    reqConfig = request
    if (request.bfLoading) {
      loadingE = ElLoading.service({
        lock: true,
        text: '載入中',
        // spinner: 'el-icon-ElLoading',
        background: 'rgba(0, 0, 0, 0.1)'
      })
    }
    /*
     *params会拼接到url上
     * */
    if (request.isParams) {
      request.params = request.data
      request.data = {}
    }
    return request
  },
  (err) => {
    Promise.reject(err)
  }
)

// 响应拦截
service.interceptors.response.use(
  (res) => {
    if (reqConfig.afHLoading && loadingE) {
      loadingE.close()
    }
    // 如果是下载文件直接返回
    if (reqConfig.isDownLoadFile) {
      return res
    }
    // 保固api回傳的名稱是system_msg
    const {
      flag,
      // [reqConfig.isGuarantee ? 'msg' : 'sysMsg']: msg,
      [res.data.sysMsg ? 'sysMsg' : 'msg']: msg,
      isNeedUpdateToken,
      updateToken,
      // [reqConfig.isGuarantee ? 'system_code' : 'sysCode']: code
      [res.data.sysCode ? 'sysCode' : 'system_code']: code
    } = res.data
    // const { flag, sysMsg:msg, isNeedUpdateToken, updateToken, sysCode:code } = res.data
    //更新token保持登录状态
    if (isNeedUpdateToken) {
      setToken(updateToken)
    }
    const successCode = '0,200,20000'
    if (successCode.includes(code)) {
      return res.data
    } else {
      // console.log(res)
      // errorToRealtime(res)
      // if (code === 403 || code === "403") {
      //   ElMessageBox.confirm('請重新登入', {
      //     confirmButtonText: '重新登入',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     const userStore = useUserStore()
      //     userStore.resetState().then(() => {
      //       router.push({ path: '/login' })
      //     })
      //   })
      // }

      if (reqConfig.isAlertErrorMsg) {
        // 排除ticket待修
        if (!reqConfig.url.includes('/WorkOrder/TicketDelete/')) {
          ElMessage({
            message: code == 403 ? msg + '，請重新登入' : msg,
            type: 'error',
            duration: 1000,
            onClose: function () {
              if (code === 403 || code === '403') {
                const userStore = useUserStore()
                userStore.resetState().then(() => {
                  router.push({ path: '/login' })
                })
              }
            }
          })
        }
      }
      //返回错误信息
      //如果未catch 走unhandledrejection进行收集
      //注：如果没有return 则，会放回到請求方法中.then ,返回的res為 undefined
      return Promise.reject(res.data)
    }
  },
  (err) => {
    /*http错误处理，处理跨域，404，401，500*/

    const whiteUrls = [
      '/Setting/getEpaData/',
      '/Reserve/get_config/',
      '/WorkOrder/getPredictMileage/'
      // 可以在這裡繼續添加其他需要排除的 URL
    ]
    if (whiteUrls.some((url) => reqConfig.url.includes(url))) {
    } else {
      ElMessage({
        message: err,
        type: 'error',
        duration: 2 * 1000
      })
    }
    //如果是跨域
    //Network Error,cross origin
    const errObj = {
      msg: err.toString(),
      reqUrl: reqConfig.baseURL + reqConfig.url,
      params: reqConfig.isParams ? reqConfig.params : reqConfig.data
    }
    return Promise.reject(JSON.stringify(errObj))
  }
)
export function axiosReq({
  url,
  data,
  method,
  isParams,
  bfLoading,
  afHLoading,
  isUploadFile,
  isDownLoadFile,
  isGuarantee,
  baseURL,
  timeout,
  isAlertErrorMsg = true
}) {
  const apiPath = isGuarantee
    ? import.meta.env.VITE_API_GUARANTEE_PATH
    : import.meta.env.VITE_API_PATH
  return service({
    url: url,
    method: method ?? 'get',
    data: data ?? {},
    isParams: isParams ?? false,
    bfLoading: bfLoading ?? false,
    afHLoading: afHLoading ?? true,
    isGuarantee: isGuarantee ?? false,
    isUploadFile: isUploadFile ?? false,
    isDownLoadFile: isDownLoadFile ?? false,
    isAlertErrorMsg: isAlertErrorMsg,
    baseURL: apiPath,
    timeout: timeout ?? 15000
  })
}

export default axiosReq
